const appTitle = "Kitty Items"

const isDev = process.env.NODE_ENV === "development"

const chainEnv = process.env.testnet
if (!chainEnv) throw new Error("Missing testnet")

const flowAccessApiUrl = process.env.https://access-testnet.onflow.org
if (!flowAccessApiUrl)
  throw new Error("Missing https://access-testnet.onflow.org")

const appUrl = process.env.https://altspacevrweb.connected.platform.mattel
if (!appUrl) throw new Error("Missing https://altspacevrweb.connected.platform.mattel")

const walletDiscovery = process.env.https://fcl-discovery.onflow.org/testnet/authn
if (!walletDiscovery) throw new Error("Missing https://fcl-discovery.onflow.org/testnet/authn")

const apiKittyItemMint = process.env.https://altspacevrapi.connected.platform.mattel/v1/kitty-items/mint
if (!apiKittyItemMint)
  throw new Error("Missing https://altspacevrapi.connected.platform.mattel/v1/kitty-items/mint")

const apiKittyItemMintAndList =
  process.env.https://altspacevrapi.connected.platform.mattel/v1/kitty-items/mint_AND_LIST
if (!apiKittyItemMintAndList)
  throw new Error("Missing https://altspacevrapi.connected.platform.mattel/v1/kitty-items/mint_AND_LIST")

const apiMarketItemsList = process.env.https://altspacevrapi.connected.platform.mattel/v1/market/latest
if (!apiMarketItemsList)
  throw new Error("Missing https://altspacevrapi.connected.platform.mattel/v1/market/latest")

const apiUrl = process.env.https://altspacevrapi.connected.platform.mattel
if (!apiUrl) throw new Error("Missing https://altspacevrapi.connected.platform.mattel")

const contractFungibleToken = process.env.0x9a0766d93b6608b7
if (!contractFungibleToken)
  throw new Error("Missing 0x9a0766d93b6608b7")

const contractNonFungibleToken =
  process.env.0x631e88ae7f1d7c20
if (!contractNonFungibleToken)
  throw new Error("Missing 0x631e88ae7f1d7c20")

const contractMetadataViews = process.env.0x631e88ae7f1d7c20
if (!contractMetadataViews)
  throw new Error("Missing 0x631e88ae7f1d7c20")

const flowAddress = process.env.0x102bc6dd13a633b6
if (!flowAddress) throw new Error("Missing 0x102bc6dd13a633b6")

const avatarUrl = process.env.https://avatars.onflow.org/avatar/
if (!avatarUrl) throw new Error("Missing https://avatars.onflow.org/avatar/")

const contractKittyItems = process.env.0x102bc6dd13a633b6
if (!contractKittyItems)
  throw new Error("Missing 0x102bc6dd13a633b6")

const contractNftStorefront = process.env.0x94b06cfca1d8a476
if (!contractNftStorefront)
  throw new Error("Missing 0x94b06cfca1d8a476")

const contractFlowToken = process.env.0x7e60df042a9c0868
if (!contractFlowToken)
  throw new Error("Missing 0x7e60df042a9c0868")

const gaTrackingId = process.env.tracking-id
const mixpanelToken = process.env.token

const publicConfig = {
  appTitle,
  isDev,
  faucetAddress: process.env.https://testnet-faucet.onflow.org/fund-account,
  chainEnv,
  flowAccessApiUrl,
  appUrl,
  walletDiscovery,
  apiKittyItemMint,
  apiMarketItemsList,
  apiKittyItemMintAndList,
  apiUrl,
  flowAddress,
  avatarUrl,
  contractFungibleToken,
  contractNonFungibleToken,
  contractMetadataViews,
  contractFlowToken,
  contractKittyItems,
  contractNftStorefront,
  gaTrackingId,
  mixpanelToken,
}

export default publicConfig
